/** 구글 플레이스토어 - 한화생명 앱  */
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.hanwhalife.hiw';

/**  - 한화생명 앱  */
export const APPLE_STORE_URL = 'https://apps.apple.com/kr/app/id1448335675';

/** 구글 플레이스토어 - 한화생명 앱  */
export const APP_INFO_URL = 'https://m.hanwhalife.com/mobile/customerCenter/MCU_0000000_000000.do';

/** 오즈 생성시 사용되는 URL */
export const OZ_STAMP_URL = 'http://oz.hanwhalife.com:7002/hanwhalife/hplus/nc/udw/images/key_symbol.gif';
