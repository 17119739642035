/*****************************
 * Html 관련 유틸
 ******************************/

/**
 * query 객체를 직렬화한 쿼리스트링 string으로 변환한다.
 * @param query
 * @returns
 */
export const serializeQueryString = (query: Record<string, any>) => {
  return Object.keys(query)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
    .join('&');
};

/**
 * query 과 query을 합친다.
 * @param query
 * @returns
 */
export const combineQueryWithQuery = (query1: string, query2: string) => {
  const checkQueryForm = (query: string) => {
    if (query.includes('?')) {
      return query.split('?')[1];
    }

    return query;
  };

  return `${checkQueryForm(query1)}&${checkQueryForm(query2)}`;
};

/**
 * query의 중복을 제거한다
 * @param query
 * @returns string
 */
export const removeDuplicateQueryParamas = (queryString: string) => {
  // URLSearchParams 객체를 사용하여 쿼리 스트링 파싱
  const params = new URLSearchParams(queryString);
  const entry = Object.fromEntries(params.entries());

  return serializeQueryString(entry);
};

/**
 * html에서 a태그 href에 쿼리스트릉일 추가한다.
 * @param html
 * @param queryObject
 * @returns
 */
export const appendQueryString = (html: string, queryString: string) => {
  try {
    // 중복 제거된 쿼리스트링
    const filteredQueryString = removeDuplicateQueryParamas(queryString);

    // HTML 문자열 파싱
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // a태그 셀렉
    const aTags = doc.querySelectorAll('a');

    // href태그 맨 뒤 쿼리스트링 추가 작업
    aTags.forEach((a) => {
      let href = a.getAttribute('href');
      if (!href) {
        return;
      }
      if (href.includes('?')) {
        href += `&${filteredQueryString}`;
      } else {
        href += `?${filteredQueryString}`;
      }
      a.setAttribute('href', href);
    });

    // 업데이트 된 HTM을 문자열로 직렬화
    const updatedHtmlString = new XMLSerializer().serializeToString(doc);

    return updatedHtmlString;
  } catch (err) {
    return html;
  }
};
