/**
 * 히스토리백 막아야하는 화면들
 */
export const BLOCK_LIST = [
  '/auth/verification/hp1',
  '/signup/2',
  '/signup/3',
  '/signup/4',
  '/signup/conversion',
  '/myinfo/update',
  '/menu/cs-notice',
  '/aml/guide',
  '/aml/complete',
  '/change-contractor/apply/?step=1&prevent_backward=true',
  '/change-contractor/apply/?step=7_1',
  '/change-contractor/apply/?step=7_2',
  '/change-contractor/apply/?step=7_3',
  '/change-contractor/apply/?step=processing',
  '/change-contractor/agreement/?step=6',
  '/change-contractor/agreement/?step=7_1',
  '/change-contractor/agreement/?step=7_2',
  // 지대청 블락 리스트
  '/app/designated-agent/agreement/?step=2',
  '/app/designated-agent/agreement/?step=3',
  '/app/designated-agent/agreement/?step=4',
  '/app/designated-agent/application/?step=5',
  '/app/designated-agent/application/?step=5_1',
  '/app/designated-agent/application/?step=5_2',
  '/app/designated-agent/application/?step=5_3'
];
